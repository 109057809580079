<template>
  <div class="admin-view-container">
    <el-form
      ref="postForm"
      label-position="right"
      label-width="100px"
      :model="postData"
      :rules="rules"
      size="small"
    >
      <el-form-item :label="$props.label + '名称'" prop="addrName">
        <el-input v-model="postData.addrName"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSubmit" :loading="loading"
          >提交</el-button
        >
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getAddrEdit } from "@/api/index.js";
export default {
  props: {
    row: Object,
    label: String,
    type: Number, //1新增，2编辑
  },
  data() {
    return {
      loading: false,
      postData: {
        addrId: "",
        addrName: "",
        addrLevel: 0,
        addrParentId: 0,
      },
      rules: {
        addrName: [
          { required: true, trigger: "change", message: "请输入地址名称" },
        ],
      },
    };
  },
  created() {
    this.labelStr = this.$props.label;
    if (this.$props.type == 2) {
      Object.assign(this.postData, this.$props.row);
    } else {
      this.postData.addrLevel = Number(this.$props.row.addrLevel) + 1;
      this.postData.addrParentId = this.$props.row.addrId;
    }
  },
  methods: {
    cancel() {
      this.$emit("end");
    },
    handleSubmit() {
      this.$refs["postForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$utils.resolveRequest(getAddrEdit(this.postData), this);
        } else {
          this.loading = false;
          this.$message.warning("请填写正确信息！");
          return false;
        }
      });
    },
  },
};
</script>